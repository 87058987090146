@media screen and (max-width: 1250px){
    .OurProducts.container .img-OurProducts-big{
        width: 35%;
    }
    .OurProducts.container .img-OurProducts-big img{
        width: 100%;
    }
    .OurProducts.container .content{
        width: 60%;
        margin-left: 3%;
    }
    .OurProducts.container .content .images img{
        margin-right: 2em;
    }
}

@media screen and (max-width: 1000px){
    .OurProducts.container{
        justify-content: center;
    }
    .OurProducts.container .content{
        width: 50%;
    }
}

@media screen and (max-width: 800px){
    .OurProducts.container{
        padding: 4em 0 3em;
        justify-content: flex-start;
    }
    .OurProducts.container .img-OurProducts-big{
        width: 40%;
    }
    .OurProducts.container .img-OurProducts-big img{
        width: 100%;
    }
    .OurProducts.container .content{
        margin-left: 5%;
    }
    .OurProducts.container .content .images img{
        width: 2.5em;
        max-height: 2.5em;
        margin-right: 1em;
    }
}

@media screen and (max-width: 500px){
    .OurProducts.container img.truck,
    .OurProducts.container .img-OurProducts-big{
        display: none;
    }
    .OurProducts.container .content{
        margin-left: 0;
        width: 100%;
    }
    .OurProducts.container .content hr{
        margin: 1.5em 0;
    }
    .OurProducts.container .content .images img{
        width: 18%;
        max-height: 4em;
        margin-right: 1em;
    }
}