.Slider{
    margin: 3em 0;
}

.Slider .swiper-slide{
    position: relative;
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center center;
}
/*buttons*/
.Slider .swiper-button-next,
.Slider .swiper-button-prev{
    border-radius: .3em;
    width: 3em;
    height: 3em;
    bottom: 35%;
    top: auto;
    background: var(--white);
    padding: .2em 1em;
    color: #4A4A4A;
    box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
    transition: .3s;
}
.Slider .swiper-button-prev{
    left: 12.5%;
}
.Slider .swiper-button-next{
    left: calc(12.5% + 4em);
}
.Slider .swiper-button-next:after,
.Slider .swiper-button-prev:after{
    font-size: 1.5em;
    font-weight: 900;
}
.Slider .swiper-button-prev:hover,
.Slider .swiper-button-next:hover{
    color: var(--white);
    width: 4em;
}
.Slider .swiper-button-next:hover{
    background: var(--grad-slider-r);
}
.Slider .swiper-button-prev:hover{
    left: calc(12.5% - 1em);
    background: var(--grad-slider-l);
}

.Slider .swiper-slide h3{
    position: absolute;
    top: 30%;
    left: 12.5%;
    max-width: 40%;
    color: var(--gray-4a);
    font-weight: 400;
}